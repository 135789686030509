<template>
  <div class="main">
    <page-header v-if="isUitemplate"/>
    <goTop v-if="isUitemplate"/>
    <router-view/>
    <page-footer v-if="isUitemplate"/>
  </div>
</template>

<script>
import PageHeader from '@/components/containers/page-header'
import goTop from '@/components/containers/goTop'
// import goTop from './components/containers/goTop'
import PageFooter from '@/components/containers/page-footer'
export default {
  name: 'Main',
  components: {
    PageHeader,
    goTop,
    PageFooter
  },
  computed: {
    isUitemplate () {
      return this.$route.fullPath !== '/uitemplate'
    }
  }
}
</script>
<style>@import "../assets/css/aboutUs.css";</style>
<style>@import "../assets/css/common.css";</style>
<style>@import "../assets/css/news.css";</style>
<style>@import "../assets/css/public.min.css";</style>

<style lang="less">
.main{
  height: 100%;
}
</style>
