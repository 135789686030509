<template>
  <div class="header">
    <div class="pcheader">
      <!-- <div :class="[{ page_header: true }, { fixing: fixing }]"> -->
      <div :class="[{ nav: true }, { fixing: fixing }]">
        <div class="left" @click="goindex">
          <!-- <img src="../../assets/image/bjlogo.png" alt="" /> -->
          <!-- <img src="../../assets/image/changan_white.png" alt="" /> -->
          <img src="../../assets/image/bingjiangchangan.png" alt="" />
        </div>
        <ul class="nav-centent">
          <li v-for="(item, index) in items" :key="item.id" @mouseover="selectStyle(item)" @mouseout="outStyle(item)">
            <a :href="item.href" @click="navclick($event, item)" :class="{ active: item.path == current }">{{ item.name
            }}</a>
            <!-- <i class="el-icon-arrow-down" v-if="item.children"></i> -->
            <el-collapse-transition>
              <ul v-show="item.isSubShow" class="submemu">
                <li v-for="subItems in item.children" :key="subItems.id">
                  <a href="#" @click="navclick($event, subItems)">{{
                    subItems.name
                  }}</a>
                </li>
              </ul>
            </el-collapse-transition>
          </li>
        </ul>
      </div>
      <!-- </div> -->
    </div>
    <div class="mobileheader">
      <div class="page_header">
        <div class="header_left left">
          <div class="log_img" @click="goindex">
            <img src="../../assets/image/bjlogo.png" alt="" />
          </div>
        </div>
        <div class="right" @click="clickdrawer">
          <i class="el-icon-s-operation"></i>
        </div>
      </div>
    </div>
    <el-drawer title="网站导航" :visible.sync="drawer" direction="rtl" size="80%">
      <el-menu default-active="2" class="el-menu-vertical-demo" background-color="#000" text-color="#fff"
        active-text-color="#0aaff1">
        <el-submenu index="productEngine" :popper-append-to-body="true" style="top: 50px !imporatant">
          <template slot="title">产品中心</template>
          <el-menu-item index="productEngine" @click="to('productEngine')">黑洞三维图形引擎</el-menu-item>
<!--          <el-menu-item index="productPlatform" @click="to('productPlatform')">星云三维数字基础平台</el-menu-item>-->
<!--          <el-menu-item index="productGalaxy" @click="to('productGalaxy')">星系智慧建造平台</el-menu-item>-->
        </el-submenu>
        <el-menu-item index="uitemp" @click="to('uitemp')">在线演示</el-menu-item>
        <el-menu-item index="case" @click="to('case')">应用案例</el-menu-item>
        <el-menu-item index="news" @click="to('news')">新闻中心</el-menu-item>
        <el-menu-item index="about" @click="to('about')">关于我们</el-menu-item>
        <el-menu-item index="service" @click="to('service')">技术服务</el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: 'page-header',
  data () {
    return {
      fixing: false,
      activeIndex: '1',
      showsubmenu: false,
      showdrawer: false,
      drawer: false,
      direction: 'rtl',
      items: [
        // {
        //   id: 1,
        //   name: '产品中心',
        //   isSubShow: false,
        //   path: 'product',
        //   children: [
        //     { id: '1-1', name: '黑洞三维图形引擎', path: 'productEngine', href: 'https://developer.bjblackhole.com', target: '_blank' },
        //     // { id: '1-2', name: '星云三维数字基础平台', path: 'productPlatform', href: 'https://nebula.bjblackhole.com/homepage/', target: '_blank' }
        //   ]
        // },
        { id: 1, name: '黑洞引擎', path: 'productEngine' },
        { id: 2, name: '在线演示', path: 'uitemp' },
        { id: 3, name: '应用案例', path: 'case' },
        { id: 4, name: '新闻中心', path: 'news' },
        { id: 5, name: '关于我们', path: 'about' },
        { id: 6, name: '技术服务', path: 'service' }
        // { id: 7, name: '产品试用', path: 'productTest' } // 市场部要求隐藏此tab-2022-11/25
      ],
      current: this.$route.path.slice(1).split('-')[0]
    }
  },
  methods: {
    handleInProduct () {
      window.open('https://wj.qq.com/s2/10287272/332c/', '_blank')
    },
    goindex () {
      this.$router.push('index')
      this.current = 0
    },
    clickdrawer () {
      this.drawer = true
    },
    to (path) {
      this.$router.push(path)
      this.drawer = false
    },
    scroll () {
      const _this = this
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop > 0) {
        this.fixing = true
      } else {
        this.fixing = false
      }
    },
    selectStyle (item) {
      item.isSubShow = true
    },
    outStyle (item) {
      item.isSubShow = false
    },
    navclick (e, item) {
      if (item.href !== undefined) {
        window.open(item.href, item.target)
        return false
      }
      if (item.path === 'productTest') {
        this.handleInProduct()
        return
      }
      if (item.path === 'product') return false
      this.$router.push(item.path)
      if (isNaN(item.id)) {
        this.current = 'product'
        e.currentTarget.parentElement.parentElement.style.display = 'none'
        return false
      }
      this.current = item.path
    }
  },
  mounted () {
    // this.$route.path = this.$route.path.replace("/","");
    // console.log(this.$route.path)
    window.addEventListener('scroll', this.scroll, true)
    if (this.current === 'productEngine' || this.current === 'productPlatform' || this.current === 'productGalaxy') {
      this.current = 'product'
    }
  }
}
</script>

<style scoped lang="less">

@media screen and (max-width: 870px) {
  .pcheader {
    display: none;
  }
  .mobileheader {
    display: block !important;
  }
}
.mobileheader {
  display: none;
  .page_header {
    background-color: #000;
    position: fixed;
    top: 0;
    z-index: 999;
    .header_left {
      margin-left: 10px;
    }
    .right {
      line-height: 50px;
      font-size: 20px;
      margin-right: 40px;
      color: #fff;
    }
  }
}
/deep/ .el-drawer {
  margin-top: 50px;
  height: calc(100% - 50px);
  background-color: #000;
  .el-drawer__header {
    padding: 10px 10px 0;
  }
  .el-dialog__close {
    // margin-right: 30px;
  }
  .el-memu {
    background-color: #000;
  }
}

.pcheader {
  .nav {
    width: calc(100% - 17px);
    height: 50px;
    background: transparent;
    position: fixed;
    top: 0;
    z-index: 999;
    .left {
      float: left;
      width: 300px;
      height: 40px;
      margin-left: 100px;
      margin-top: 2px;
      @media (min-width: 768px) and (max-width: 1100px) {
        margin-left: 10px;
      }
      img {
        // width: 50%;
        // height: 4.5vh;
        cursor: pointer;
      }
      img:last-child{
        // height: 3.8vh;
      }
    }
    .nav-centent {
      float: left;
    }
  }
  .nav .nav-centent {
    height: 50px;
    // margin-left: 50px;
    @media (min-width: 768px) and (max-width: 1100px) {
      margin-left: 10px;
    }
  }
  .nav .nav-centent > li {
    width: 100px;
    height: 50px;
    display: block;
    float: left;
    text-align: center;
    // margin-right: 20px;
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    @media (min-width: 768px) and (max-width: 1010px) {
      width: 70px;
    }
  }
  .nav .nav-centent li:first-child {
    width: 120px;
    //  @media (min-width: 768px) and (max-width: 992px) {
    //   width: 106px;
    // }
  }
  .nav .nav-centent li a {
    // width: 120px;
    height: 50px;
    display: block;
    font-size: 14px;
    line-height: 50px;
    color: #fff;
  }
  .nav .nav-centent ul {
    position: absolute;
    left: 0;
    top: 50px;
    z-index: 99;
    width: 100%;
  }
  .nav .nav-centent ul li {
    // width: 120px;
    height: 50px;
    display: block;
    float: left;
    text-align: center;
    margin-right: 20px;
    line-height: 50px;
    background: #000;
  }
  .nav .nav-centent li a:after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background: #fff;
    bottom: 8px;
    transition: all 0.8s;
    left: 50%;
  }
  .nav .nav-centent li a.active:after,
  .nav .nav-centent li a:hover:after {
    width: 100%;
    left: 0;
  }
  .nav .submemu{
    background-color: #1b1b1b;
    li {
      width: 100%;
      height: 40px !important;
      line-height: 40px !important;
      a {
        height: 40px !important;
        line-height: 40px !important;
        font-size: 12px;
      }
    }
  }

  .nav .submemu li a:hover {
    background-color: #2f2f2f;
  }
  .nav .submemu li a:hover:after {
    width: 0;
    left: 0;
  }
}
</style>
