<template>
  <!-- 回到顶部 -->
  <div :class="btnClass" @click="scrollToTop" id="goTop">
    <img src="../../assets/image/arrow_up.png" alt />
  </div>
</template>

<script>
export default {
  name: 'goTop',
  data () {
    return {
      btnClass: 'arrow_up'
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll, false)
  },
  destroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll () {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      this.btnClass = scrollTop > 150 ? 'arrow_up show' : 'arrow_up'
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style>
</style>
