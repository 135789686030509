<template>
  <div class="page_footer">
<!--    <div class="chart_list">-->
<!--      <div class="chart_title">-->
<!--        产品或项目咨询-->
<!--      </div>-->
<!--      <ul class="chart_cont">-->
<!--        <li>-->
<!--          <p class="chart_cont_tit">华东-->
<!--            <i class="el-icon-caret-right"></i>-->
<!--          </p>-->
<!--          <div>石朗忠： 13921636108</div>-->
<!--        </li>-->
<!--        <li>-->
<!--          <p class="chart_cont_tit">华北、东北、两湖-->
<!--            <i class="el-icon-caret-right"></i>-->
<!--          </p>-->
<!--          <div>王振清： 13718413836</div>-->
<!--        </li>-->
<!--        <li>-->
<!--          <p class="chart_cont_tit">华南-->
<!--            <i class="el-icon-caret-right"></i>-->
<!--          </p>-->
<!--          <div>郭 倩： 13628508343</div>-->
<!--        </li>-->
<!--        <li>-->
<!--          <p class="chart_cont_tit">西南-->
<!--            <i class="el-icon-caret-right"></i>-->
<!--          </p>-->
<!--          <div>张 洋： 13399880797</div>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
    <div class="container">
      <div class="btm_container">
        <div class="left">
          <div class="logo">
            <!-- <img class="" src="../../assets/logobig.png" alt /> -->
            <!-- <img class="" src="../../assets/image/changan_white.png" alt /> -->
            <img class="" src="../../assets/image/bingjiangchangan.png" alt />
          </div>
          <p class="">官方新媒体</p>
          <div class="share">
            <div class="weixin">
              <img class="center_picture_loogwd"  @mouseover="changeActive()" @mouseout="removeActive()" src="../../assets/image/wxlogo.png" alt />
              <div class="position" v-show="showweixin"><img ref="a" src="../../assets/image/weixin_new.jpg"></div>
            </div>
            <div class="douyin">
              <img class="center_picture_loogwd" @mouseover="changeActive2()" @mouseout="removeActive2()" src="../../assets/image/dylogo.png" alt />
              <div class="position" v-show="showdouyin"><img ref="a" src="../../assets/image/douyin_new.png"></div>
            </div>
          </div>
      </div>
      <div class="right">
        <p>上海秉匠信息科技有限公司</p>
        <p>地址：上海市闵行区宜山路1698号兴迪商务大厦1506室</p>
        <!-- <p>郭倩：136 2850 8343</p>
        <p>华中、北方地区 王振清：137 1841 3836</p>
        <p>南方地区 石朗忠 ：139 2163 6108</p> -->
        <p style="display: flex;align-items: center;"><img style="width:18px;" src="../../assets/phone.png" alt="" />郭倩：136 2850 8343</p>
        <p style="display: flex;align-items: center;"><img style="width:18px;" src="../../assets/phone.png" alt="" />华中、北方地区 王振清：137 1841 3836</p>
        <p style="display: flex;align-items: center;"><img style="width:18px;" src="../../assets/phone.png" alt="" />南方地区 石朗忠 ：139 2163 6108</p>
        <!-- <p>南通秉匠信息科技有限公司</p>
        <p>地址：南通高新区新世纪大道266号江海智汇园A2楼411-412 </p>
        <p>电话：0513-86915888</p> -->
      </div>
      </div>
      <div class="footer_bottom">
        <p>Copyright © 2017-2024 上海秉匠信息科技有限公司 版权所有</p>
        <a href="http://beian.miit.gov.cn" target="_blank">沪ICP备18040952号-4</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-header',
  data () {
    return {
      showweixin: false,
      showdouyin: false
    }
  },
  methods: {
    changeActive () {
      this.showweixin = true
    },
    removeActive () {
      this.showweixin = false
    },
    changeActive2 () {
      this.showdouyin = true
    },
    removeActive2 () {
      this.showdouyin = false
    }
  }
}

</script>

<style scoped lang="less">
*{
  box-sizing: border-box;
}
.page_footer{
  background: #000;
  // height: 60vh;
}
.router-link-active{
    color: #0081FF !important;
    border: none !important;
}
.container{
  overflow: hidden;
  padding: 80px 0 40px;
  // padding-bottom: 60px;
  @media screen and (max-width:768px) {
    padding: 40px 10px 40px;
  }
  .left{
    width: 40%;
    .logo{
      width: 80%;
      @media screen and (max-width:768px) {
        width: 70%;
      }
    }
    p{
      font-size: 22px;
      margin-left: 54px;
      margin-top: 15px;
      float: left;
      @media screen and (max-width:768px) {
        font-size: 20px;
        margin-left: 10px;
      }
    }
    .share{
      // width: 60%;
      float: left;
      margin-left: 20px;
      // margin-top: 20px;
      margin-top: 15px;
      @media screen and (max-width:768px) {
        width: 75%;
        margin-left: 0;
      }
      div{
        width: 50px;
        float: left;
        margin-right: 10px;
        position: relative;
        cursor: pointer;
      }
      .weixin{
        .position{
          width: 100px;
          height: 100px;
          position: absolute;
          background-color: #fff;
          top: 65px;
          left: 30px;
          border-radius: 7px;
          padding: 4px;
        }
        .position:before {
          content: '';
          background: url('../../assets/image/sanjiao.png') no-repeat top left /26px 16px;
          position: absolute;
          top: -14px;
          z-index: 2;
          width: 32px;
          height: 16px;
       }
      }
      .douyin{
        .position{
          width: 100px;
          height: 100px;
          position: absolute;
          background-color: #fff;
          top: 65px;
          left: 30px;
          border-radius: 7px;
          padding: 4px;
        }
        .position:before {
          content: '';
          background: url('../../assets/image/sanjiao.png') no-repeat top left /26px 16px;
          position: absolute;
          top: -14px;
          z-index: 2;
          width: 32px;
          height: 16px;
       }
      }
    }
  }
  .right{
    width: 60%;
    p{
      font-size: 16px;
      line-height: 32px;
      @media screen and (max-width:768px) {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
}
.chart_list {
  // height: 300px;
  padding: 0 4% 6% 4%;
  background: #fff;
  display: flex;
  flex-direction: column;
  color: #333;
  justify-content: center;
  align-items: center;
  .chart_title {
    text-align: center;
    font-size: 34px;
    padding:0;
    // font-weight: 700;
    margin-bottom: 3%;
  }
  .chart_cont {
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    width: 100%;
    font-size: 16px;
    .chart_cont_tit {
      font-size: 22px;
      margin-bottom: 20px;
      position: relative;
      i {
        position: absolute;
        left: -26px;
        top: 4px;
      }
    }
  }
}
</style>
